import { throttle } from '/js/utils.js';

// TODO: включать режим observers также на маленьком экране
class cardImgSlider {
  constructor(node, options) {
    this.rotator = (typeof node === 'string') ? document.querySelector(node) : node;
		this.indicators = this.rotator.querySelectorAll('.card-img-indicator > span')
		this.imgs = this.rotator.querySelectorAll('img')
		this.segmentWidth = 1
		this.activated = false

		if (this.imgs.length > 1) {
			this.rotator.addEventListener('mouseenter', this.attachMouseTracker)
			this.rotator.addEventListener('mouseleave', this.detachMouseTracker)
			this.rotator.classList.add('rotator-initialized')
		}
  }

	attachMouseTracker = (e) => {
		let segmentFract = 1 / this.imgs.length
		let rotatorDims = this.rotator.getBoundingClientRect();

		this.segmentWidth = segmentFract * rotatorDims.width
		this.activated = true

		setTimeout(() => {
			if (this.activated) {
				this.trackMouseThrottled(e)
				this.rotator.addEventListener('mousemove', this.trackMouseThrottled);
			}
		}, 250)
	}

	detachMouseTracker = (e) => {
		this.activated = false
		this.setActive(0)
		this.rotator.removeEventListener('mousemove', this.trackMouseThrottled)
	}

	trackMouse = (e) => {
		let segNum = Math.floor( (Math.abs(e.layerX) - 1) / this.segmentWidth);
		this.setActive(segNum)
	}

	setActive = (idx) => {
		this.rotator.querySelector('.card-img-indicator >.active')?.classList.remove('active')
    this.rotator.querySelector('img.active')?.classList.remove('active')
    this.indicators[idx]?.classList.add('active');
    this.imgs[idx]?.classList.add('active');
	}

	trackMouseThrottled = throttle(100, this.trackMouse, { noTrailing: true });

	destroy() {
    this.rotator.removeEventListener('mouseenter', this.attachMouseTracker)
    this.rotator.removeEventListener('mouseleave', this.detachMouseTracker)
  }
}


function initCardImgSliders(element) {
	let observers = []
	let rotators = []
	let container = element ? (element.target ? element.target : element) : document

	const initRotators = function () {
		let cardImgRotators = container.querySelectorAll('.catalog-card .card-img:not(.rotator-initialized)');

		cardImgRotators.forEach(rotator => {
			let imgs = rotator.querySelectorAll('img')
			if (imgs.length > 1) {
				rotators.push(new cardImgSlider(rotator))
				rotator.classList.add('rotator-initialized')
			}
			else {
				rotator.querySelector('.card-img-indicator').classList.add('d-none');
			}
		})
	}

	const initObservers = function () {

		if ("IntersectionObserver" in window) {
			let cardImgRotators = container.querySelectorAll('.catalog-card .card-img:not(.rotator-initialized)');

			const highlightInView = target => {
				let imgs = target.querySelectorAll('img')

				const interSecObs = new IntersectionObserver(entries => {
					entries.forEach(entry => {
						if(entry.isIntersecting) {
							let idx = Array.prototype.indexOf.call(imgs, entry.target);

							target.querySelector('.list-group-item.active').classList.remove('active');
							target.querySelector('.card-img-indicator > span:nth-child('+(idx+1)+')').classList.add('active');
						}
					});
				}, {
					root: target,
					rootMargin: "0px",
					threshold: .5
				});
				imgs.forEach(img => {
					interSecObs.observe(img);
				})

				observers.push(interSecObs);
			};
			cardImgRotators.forEach(el => {
				if (el.querySelectorAll('img').length > 1) {
					highlightInView(el)
					el.classList.add('rotator-initialized')
				}
				else el.querySelector('.card-img-indicator').classList.add('d-none');
			});
		}
	}


	enquire.register('screen and (hover: hover) and (pointer: fine)', {
		match: function () {
			observers.forEach(observer => {
				observer.root.classList.remove('rotator-initialized')
				observer.disconnect()
			});
			observers = [];

			initRotators()
		}
	})
	enquire.register('screen and (pointer: coarse)', {
		match: function () {
			rotators.forEach(rotator => {
				rotator.rotator.classList.remove('rotator-initialized')
				rotator.destroy()
			});
			rotators = []

			initObservers()
		}
	})
}
document.addEventListener('DOMContentLoaded', initCardImgSliders)


export default initCardImgSliders
